import { useEffect, useState } from 'react'
import '../src/assets/scss/app.scss'
import LogoWhite from './assets/images/logo-white.svg'
import UserCircle from './assets/images/user-circle.svg'
import Menu from './assets/images/menu.svg'
import BannerHeader from './assets/images/header-banner.png'
import StepCircle from './components/StepCircle'
import Arrow from './assets/images/arrow-right.svg'
import Accordion from './components/Accordion'
import Divider from './components/Divider'
import BannerLeads from './assets/images/banner-leads.png'
import BannerDream from './assets/images/banner-dreams.png'
import OctoUser from './assets/images/octo-user.svg'
import WindowSvg from './assets/images/window.svg'
import BannerDashboard from './assets/images/banner-dashboard.png'
import BannerMarketing from './assets/images/banner-marketing.png'
import LogoDark from './assets/images/logo-black.svg'
import Facebook from './assets/images/facebook.svg'
import Instagram from './assets/images/instagram.svg'
import Linkedin from './assets/images/linkedin.svg'
import Youtube from './assets/images/youtube.svg'
import LogoGroupDanki from './assets/images/logo-group-danki-code.svg'
import ModalRegister from './components/ModalRegister'
import Modal from 'react-modal'
import CardPlan, { PlanProps } from './components/CardPlan'

Modal.setAppElement('#root')

type Props = {
  title: string
  content: string
}

function App() {
  const [isOpen, setIsOpen] = useState(false)
  const faq: Props[] = [
    {
      title: 'Quais tipos de formatos de ebook são suportados pelo gerador?',
      content:
        'O nosso gerador de ebooks com inteligência artificial suporta uma ampla variedade de formatos populares, incluindo EPUB, MOBI, PDF e AZW3. Isso garante que os ebooks gerados possam ser lidos e acessados em diversos dispositivos e aplicativos de leitura, proporcionando uma experiência de leitura confortável e adaptável às necessidades dos seus leitores.'
    },
    {
      title: 'O gerador de ebooks pode ser utilizado em diferentes idiomas?',
      content: 'Sim, a nossa ferramenta suporta todas as línguas.'
    },
    {
      title:
        'Qual o limite de tamanho ou quantidade de páginas para o ebook gerado?',
      content:
        'Não existe um limite de páginas que você pode adicionar ao seu Ebook, no entanto durante o processo de criação existe uma quantidade máxima de capítulos, atualmente são 30 capítulos e dependendo do conteúdo podem gerar até 56 páginas inicialmente. Você pode adicionar novas páginas ao seu Ebook depois de pronto.'
    },
    {
      title: 'É possível incluir imagens no ebook gerado?',
      content:
        'Sim, temos modelos treinados para textos e imagens. Os modelos do Ebook Generator estão constantemente evoluindo, pois utilizamos diversas técnicas de Machine Learning, Deep Learning e Fine Tuning.'
    },
    {
      title: 'Tenho suporte após adquirir o Ebook Generator?',
      content:
        'Dentro da ferramenta, nossa equipe está sempre disponível para ajudá-lo durante o horário comercial. Além disso, como assinante, você é bem-vindo para sugerir novos recursos e melhorias.'
    },
    // {
    //   title: 'Onde assisto as aulas oferecidas pelo Ebook Generator?',
    //   content:
    //     'Dentro da ferramenta, há uma seção "Aulas". Ela é facilmente acessível para que você possa estudar e aplicar imediatamente 😄'
    // },
    {
      title: 'O conteúdo gerado possui direitos autorais?',
      content:
        'Todo o conteúdo gerado, incluindo ebooks, pertence a você. Como utilizamos inteligência artificial, o conteúdo é único e gerado instantaneamente.'
    },
    {
      title: 'Como funciona a personalização do ebook?',
      content:
        'A personalização do ebook no Ebook Generator é um processo simples e intuitivo. A ferramenta permite que você escolha entre diversos modelos de design, cores e fontes para tornar seu ebook visualmente atraente. Além disso, você tem a opção de adicionar imagens e gráficos relevantes. Uma vez que você tenha inserido o conteúdo, a inteligência artificial do nosso sistema irá estruturá-lo de forma lógica e coerente, criando um ebook profissional. Você pode fazer quantas alterações quiser até estar satisfeito com o resultado final. Com o Ebook Generator, a personalização do seu ebook é sempre ao seu alcance.'
    },
    {
      title: 'Existe alguma restrição de tema para a criação do ebook?',
      content:
        'No Ebook Generator, acreditamos na liberdade de expressão e na diversidade de ideias. Portanto, não impomos restrições de tema para a criação do ebook. Você é livre para escolher qualquer assunto que desejar, seja ficção, não-ficção, educacional, auto-ajuda, culinária, entre outros. No entanto, é importante observar que o conteúdo do ebook deve estar em conformidade com as leis e regulamentos locais e internacionais. Não permitimos a criação de conteúdo que promova o ódio, a discriminação, a violência ou qualquer forma de atividade ilegal.'
    }
  ]
  const plans: PlanProps[] = [
    {
      title: 'Plano Ebook Pro',
      recurrency: 'Mensal',
      subtitle: 'Pagamento Mensal<br/> &nbsp;',
      price: '79,00',
      url: 'https://checkout.dankicode.ai/ebook-generator-mensal/plan',
      items: [
        'Crie ebooks',
        'Crie Audio Books ilimitados <span>Novo</span>',
        'Opções avançadas de exportação',
        // 'Suporte a múltiplos formatos de ebooks (EPUB, PDF, MOBI)',
        'Suporte a formato de ebooks (PDF)',
        'Opções de modelos de design básicos',
        'Ferramentas de edição de texto intuitivas',
        'Suporte ao cliente durante o horário comercial',
        'Ferramentas avançadas de edição de texto',
        'Suporte para adição de elementos interativos (como links e imagens)',
        'Opção para adicionar uma capa personalizada',
        // 'Ebook em qualquer idioma'
        'Ebook em Português, Inglês e Espanhol'
      ]
    },
    {
      title: 'Plano Ebook Pro',
      subtitle: `Pagamento Anual <span>20% OFF</span> <br/> <strong>Valor total R$ 758,40</strong>`,
      // subtitle: `Pagamento Anual <span>30% OFF</span> <br/> <strong>Valor total de <s>758,40</s> por R$ 663,60</strong>`,
      recurrency: 'Anual',
      price: '63,20',
      // price: '55,30',
      url: 'https://checkout.dankicode.ai/ebook-generator-anual/plan',
      items: [
        'Crie ebooks',
        'Crie Audio Books ilimitados <span>Novo</span>',
        'Opções avançadas de exportação',
        // 'Suporte a múltiplos formatos de ebooks (EPUB, PDF, MOBI)',
        'Suporte a formato de ebooks (PDF)',
        'Opções de modelos de design básicos',
        'Ferramentas de edição de texto intuitivas',
        'Suporte ao cliente durante o horário comercial',
        'Ferramentas avançadas de edição de texto',
        'Suporte para adição de elementos interativos (como links e imagens)',
        'Opção para adicionar uma capa personalizada',
        // 'Ebook em qualquer idioma'
        'Ebook em Português, Inglês e Espanhol'
      ],
      recomended: true
    },
    {
      title: 'Plano Ebook Pro',
      subtitle: `Pagamento Semestral <span>12.5% OFF</span> <br/> <strong>Valor total R$ 414,75</strong>`,
      recurrency: 'Semestral',
      price: '69,12',
      url: 'https://checkout.dankicode.ai/ebook-generator-semestral/plan',
      items: [
        'Crie ebooks',
        'Crie Audio Books ilimitados <span>Novo</span>',
        'Opções avançadas de exportação',
        // 'Suporte a múltiplos formatos de ebooks (EPUB, PDF, MOBI)',
        'Suporte a formato de ebooks (PDF)',
        'Opções de modelos de design básicos',
        'Ferramentas de edição de texto intuitivas',
        'Suporte ao cliente durante o horário comercial',
        'Ferramentas avançadas de edição de texto',
        'Suporte para adição de elementos interativos (como links e imagens)',
        'Opção para adicionar uma capa personalizada',
        // 'Ebook em qualquer idioma'
        'Ebook em Português, Inglês e Espanhol'
      ]
    }
  ]

  const handleOpenModal = (e: any) => {
    e.preventDefault()
    const isSafari =
      //@ts-ignore
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]'
      })(
        //@ts-ignore
        !window['safari'] ||
        //@ts-ignore
        (typeof safari !== 'undefined' && window['safari'].pushNotification)
      )

    setIsOpen(true)
    if (isSafari) {
      //
    } else {
      //
    }
  }
  const [referrer, setReferrer] = useState('')
  const [utmSource, setUtmSource] = useState('')

  useEffect(() => {
    setReferrer(document.referrer)
    setUtmSource(document.location.search)
  }, [])

  return (
    <div className="app">
      <ModalRegister isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} />

      {/* <div className="header-bf">
        <p>Aproveite nossa oferta de </p>

        <a href="#plans">
          <div className="black-november">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 9H9.01M15 15H15.01M16 8L8 16M7.33377 3.8187C8.1376 3.75455 8.90071 3.43846 9.51447 2.91542C10.9467 1.69486 13.0533 1.69486 14.4855 2.91542C15.0993 3.43846 15.8624 3.75455 16.6662 3.8187C18.5421 3.96839 20.0316 5.45794 20.1813 7.33377C20.2455 8.1376 20.5615 8.90071 21.0846 9.51447C22.3051 10.9467 22.3051 13.0533 21.0846 14.4855C20.5615 15.0993 20.2455 15.8624 20.1813 16.6662C20.0316 18.5421 18.5421 20.0316 16.6662 20.1813C15.8624 20.2455 15.0993 20.5615 14.4855 21.0846C13.0533 22.3051 10.9467 22.3051 9.51447 21.0846C8.90071 20.5615 8.1376 20.2455 7.33377 20.1813C5.45794 20.0316 3.96839 18.5421 3.8187 16.6662C3.75455 15.8624 3.43846 15.0993 2.91542 14.4855C1.69486 13.0533 1.69486 10.9467 2.91542 9.51447C3.43846 8.90071 3.75455 8.1376 3.8187 7.33377C3.96839 5.45794 5.45794 3.96839 7.33377 3.8187ZM9.5 9C9.5 9.27614 9.27614 9.5 9 9.5C8.72386 9.5 8.5 9.27614 8.5 9C8.5 8.72386 8.72386 8.5 9 8.5C9.27614 8.5 9.5 8.72386 9.5 9ZM15.5 15C15.5 15.2761 15.2761 15.5 15 15.5C14.7239 15.5 14.5 15.2761 14.5 15C14.5 14.7239 14.7239 14.5 15 14.5C15.2761 14.5 15.5 14.7239 15.5 15Z"
                stroke="url(#paint0_linear_8_1662)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_8_1662"
                  x1="12"
                  y1="2"
                  x2="12"
                  y2="22"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FFD500" />
                  <stop offset="1" stop-color="#C27A08" />
                </linearGradient>
              </defs>
            </svg>
            <p>Black November</p>
          </div>
        </a>
      </div> */}

      <header id="home">
        <a href="/">
          <img src={LogoWhite} alt="Ebook Generator" />
        </a>

        <div className="dropdown">
          <div className="menu">
            <img src={Menu} alt="Ebook Generator" />
          </div>

          <nav className="header-buttons">
            <a href="https://app.ebookgenerator.ai" className="ghost-link">
              <img src={UserCircle} alt="" />
              Login
            </a>

            <a href="#plans" className="button-link">
              Criar conta
            </a>
          </nav>
        </div>
      </header>

      <div className="sub-header">
        <div className="first">
          <p className="title">Crie ebooks incríveis usando a nossa IA</p>
          <p className="subtitle">
            Conheça nossa ferramenta de geração automática e transforme suas
            ideias em livros digitais em questão de minutos.
          </p>
          <a
            href="#plans"
            // onClick={handleOpenModal}
            title="Gerar meu primeiro ebook"
            className="btn btn-primary btn-size-lg"
          >
            Gerar meu primeiro ebook
          </a>
        </div>

        <div className="second">
          <img src={BannerHeader} alt="IA Trabalhando" />
        </div>
      </div>

      <main id="about">
        <div className="video-box">
          <iframe
            src="https://player.vimeo.com/video/865119832?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            allow="autoplay; fullscreen; picture-in-picture"
            title="DankiCode AI - Ebook Generator"
          />
        </div>

        <section className="section-header">
          <h1 className="title">Plataforma de geração de ebooks</h1>
          <h2 className="subtitle">
            Descubra uma <strong>nova forma</strong> de gerar ebooks com poucos
            cliques
          </h2>

          <article className="steps">
            <div className="card-step">
              <StepCircle n={1} />
              <p>Escreve sobre o tema do seu ebook</p>
            </div>
            <img src={Arrow} alt="Etapas" />
            <div className="card-step">
              <StepCircle n={2} />
              <p>Escolha a cor e o melhor template</p>
            </div>
            <img src={Arrow} alt="Etapas" />
            <div className="card-step">
              <StepCircle n={'✌'} />
              <p>Pode deixar o resto com a gente</p>
            </div>
          </article>
        </section>

        <Divider />

        <section className="leads">
          <img src={BannerLeads} alt="Leads" />

          <article>
            <img src={OctoUser} alt="Lead de usuários" />
            <h1>Acompanhe quem acessou e baixou o seu ebook</h1>
            <p>
              Acompanhe leads gerados pelo interesse no seu ebook e aproveite os
              emails capturados para melhorar estratégias de marketing e
              relacionamento com potenciais clientes.
            </p>
          </article>
        </section>

        <Divider />

        <section className="dream">
          <article>
            <img src={WindowSvg} alt="Invista com sabedoria" />
            <h1>Tenha uma página exclusiva para divulgação</h1>
            <p>
              Com nosso gerador, você não só terá um material de alta qualidade,
              mas também uma página de captura otimizada para atrair a atenção e
              incentivar o download do seu ebook.
            </p>
          </article>
          <img src={BannerDream} alt="Invista com sabedoria" />
        </section>

        <img
          className="dashboard"
          src={BannerDashboard}
          alt="Dashboard Ebook Generator"
        />

        <section className={'plans'} id="plans">
          <h1>Escolha o plano perfeito para você</h1>
          <p>Transforme suas ideias em ebooks de forma rápida e acessível</p>

          <div>
            {plans.map((plan, index) => (
              <CardPlan
                key={index}
                recurrency={plan.recurrency}
                title={plan.title}
                subtitle={plan.subtitle}
                price={plan.price}
                items={plan.items}
                url={`${plan.url}/?referer=${referrer}${utmSource.replace(
                  '?',
                  '&'
                )}`}
                recomended={plan?.recomended}
              // openModal={handleOpenModal}
              />
            ))}
          </div>
        </section>

        <section className="end-section">
          <h1 className="title-animation">
            <span>Crie, Divulgue, Conquiste. Crie, Divulgue, Conquiste.</span>
            <span>Crie, Divulgue, Conquiste. Crie, Divulgue, Conquiste.</span>
            <span>Crie, Divulgue, Conquiste. Crie, Divulgue, Conquiste.</span>
          </h1>

          <article>
            <div className="container">
              <div>
                <header>
                  <h1>Estratégia de Marketing</h1>
                  <p>
                    Utilize a nossa inteligência artificial para criar ebooks e{' '}
                    <strong>alavancar seus resultados</strong>
                  </p>
                </header>

                <a
                  href="#plans"
                  // onClick={handleOpenModal}
                  className="btn btn-primary btn-size-lg"
                >
                  Quero criar meu ebook
                </a>
              </div>

              <img src={BannerMarketing} alt="Estratégia de Marketing" />
            </div>
          </article>
        </section>

        <section className="faq">
          <article>
            <h1>Dúvidas frequentes</h1>
            <p>
              Ainda tem dúvidas? Confira as perguntas mais recorrentes e acelere
              seu processo!
            </p>
          </article>

          <div id="faq">
            <Accordion items={faq} />
          </div>
        </section>
      </main>

      <footer>
        <div className="sub-footer">
          <div>
            <a href="#home">
              <img src={LogoDark} alt="Ebook Generator" />
            </a>
            <p>
              Com a ajuda da inteligência artificial, o Ebook Generator produz
              Ebooks e Audio Books incríveis de uma forma simples! Você pode
              criar poderosos Ebooks, editá-los a sua maneira para comercializar
              na internet.{' '}
            </p>
          </div>
          <div>
            <h2>Institucional</h2>
            <a href="#plans">Cadastre-se</a>
            <a href="#about">Sobre a plataforma</a>
            <a href="#faq" title="FAQ">
              FAQ
            </a>
            <a
              href="https://checkout.dankicode.ai/ebook-generator-mensal/terms"
              target="_blank"
              title="Termos de uso" rel="noreferrer"
            // onClick={handleOpenModal}
            >
              Termos de uso
            </a>
          </div>
          <div>
            <h2>Outras plataformas</h2>
            <a
              rel="noreferrer"
              href="https://cursos.dankicode.com"
              target="_blank"
              title="Danki Code"
            >
              Danki Code
            </a>
            <a
              rel="noreferrer"
              href="https://gptmax.ai"
              target="_blank"
              title="GPT Max"
            >
              GPT Max
            </a>
            <a
              rel="noreferrer"
              href="https://copygenerator.ai"
              target="_blank"
              title="Copy Generator"
            >
              Copy Generator
            </a>
            <a
              rel="noreferrer"
              href="https://oferta-gpt-32k.dankicode.ai/"
              target="_blank"
              title="GPT 32k com 128mil tokens"
            >
              GPT 32K
            </a>
          </div>
          <div>
            <h2>Acompanhe-nos</h2>

            <div className="medias">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://facebook.com/grupodankicodeoficial"
              >
                <img src={Facebook} alt="Facebook do Ebook Generator" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/grupodankicodeoficial/"
              >
                <img src={Instagram} alt="Instagram do Ebook Generator" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://br.linkedin.com/in/grupo-danki-code-oficial-1698b1209"
              >
                <img src={Linkedin} alt="Linkedin do Ebook Generator" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://youtube.com/dankicode"
              >
                <img src={Youtube} alt="Youtube do Ebook Generator" />
              </a>
            </div>

            <h2>Contato Suporte</h2>
            <div className="support">
              <a href="mailto:atendimento@dankicode.ai">
                E-mail: atendimento@dankicode.ai
              </a>
              <a href="https://dankicode.ai/contato/"
                target='_blank'
              >
                Suporte
              </a>
            </div>
          </div>
        </div>

        <div className="copy">
          <p>© {new Date().getFullYear()} - Todos os direitos reservados</p>
          <a
            href="https://dankicode.com"
            title="Grupo Danki Code"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LogoGroupDanki} alt="Grupo Danki Code" />
          </a>
        </div>
      </footer>
    </div>
  )
}

export default App
